import 'styles/powysHome.scss'
import { cym } from "../utils/cymTranslation.js";

// import "../base/home.js"
// import {Urls} from '../constants'
// import moment from 'moment';
// import { loader } from "../utils/loader.js";
// import { displayDateTime } from "../utils/displayDateTime.js";


(function () {
    /* global $ */
    "use strict";   

    let currentState = {
        attributes: '',
        page: 1,
        pageCount: 1,
        ItemsPerPage: '12',
        SortColumn: 'TransactionDate',
        SortType: 'asc',
        search: ''
    }

    async function getPaListEndpoint() {
        const endpoint = new URL(`${window.location.protocol}//${window.location.host}/s4s/api/Event/GetEventsJson`);
        
        endpoint.searchParams.set('attrValue', currentState.attributes);
        endpoint.searchParams.set('templateId', siteConstants.paTemplate);
        endpoint.searchParams.set('orgId', siteConstants.orgId);
        endpoint.searchParams.set('search', currentState.search);
        endpoint.searchParams.set('page', currentState.page);
        endpoint.searchParams.set('itemsPerPage', currentState.ItemsPerPage);
        endpoint.searchParams.set('fields', 'Id,Name,ListImagePath,Description,Address,Postcode,Phone,Email,Website,Attributes');
        endpoint.searchParams.set('isFullPhraseMatch', 'true');
        endpoint.searchParams.set('SortType', currentState.SortType);

        const response = await fetch(endpoint);
        const data = await response.json();
        // console.log(data)
        return data;
    }

    async function getVacanciesListEndpoint() {
		const endpoint = new URL(`${window.location.protocol}//${window.location.host}/s4s/api/Event/GetEventsJson`);
        
        endpoint.searchParams.set('attrValue', currentState.attributes);
        endpoint.searchParams.set('templateId', siteConstants.vacanciesTemplate);
		endpoint.searchParams.set('orgId', siteConstants.orgId);
        endpoint.searchParams.set('search', currentState.search);
        endpoint.searchParams.set('page', currentState.page);
        endpoint.searchParams.set('itemsPerPage', currentState.ItemsPerPage);
        endpoint.searchParams.set('fields', 'Id,Name,ListImagePath,Description,Address,Postcode,Phone,Email,Website,Attributes');
        endpoint.searchParams.set('isFullPhraseMatch', 'true');
        endpoint.searchParams.set('SortType', currentState.SortType);

		const response = await fetch(endpoint);
		const data = await response.json();
        // console.log(data)
		return data;
	}

    async function createMyVacanciesPanel() {
        const getVacancies = await getVacanciesListEndpoint();

        if(getVacancies.Paging.TotalItemsCount === 1){
            $('.js-available-vacancies h3').text(`${getVacancies.Paging.TotalItemsCount}`)
            $('.js-available-vacancies p').text('Available job')
            if ( localStorage.getItem('language') === 'cym') { 
                $('.js-available-vacancies p').text('Swyddi sydd ar gael');
            }
        } else if(getVacancies.Paging.TotalItemsCount === -1){
            $('.js-available-vacancies h3').text('0')
            $('.js-available-vacancies p').text('Available jobs')
            if ( localStorage.getItem('language') === 'cym') { 
                $('.js-available-vacancies p').text('Swyddi sydd ar gael');
            }
        }else{
            $('.js-available-vacancies h3').text(`${getVacancies.Paging.TotalItemsCount}`)
            $('.js-available-vacancies p').text('Available jobs')
            if ( localStorage.getItem('language') === 'cym') { 
                $('.js-available-vacancies p').text('Swyddi sydd ar gael');
            }
        }
    }  


    async function createMyPaPanel() {
        const getVacancies = await getPaListEndpoint();

        if(getVacancies.Paging.TotalItemsCount === 1){
            $('.js-available-pa .h3').text(`${getVacancies.Paging.TotalItemsCount}`)
            $('.js-available-pa p').text('Available PA in your area')
            if ( localStorage.getItem('language') === 'cym') { 
                $('.js-available-pa p').text('Cynorthwyydd personol ar gael yn eich ardal');
            }
        } else if(getVacancies.Paging.TotalItemsCount === -1){
            $('.js-available-pa .h3').text('0')
            $('.js-available-pa p').text('Available PAs in your area')
            if ( localStorage.getItem('language') === 'cym') { 
                $('.js-available-pa p').text('Cynorthwy-ydd Personol ar gael yn eich ardal');
            }
        }else{
            $('.js-available-pa .h3').text(`${getVacancies.Paging.TotalItemsCount}`)
            $('.js-available-pa p').text('Available PAs in your area')
            if ( localStorage.getItem('language') === 'cym') { 
                $('.js-available-pa p').text('Cynorthwy-ydd Personol ar gael yn eich ardal');
            }
        }

    }    

    
    function createPage() {
        createDashboardBody();
        createMyPaPanel()
        createMyVacanciesPanel()
    }

    function createDashboardBody(){

        $('.js-home').append(
            `
            <h1 style="text-indent: -10000px; height: 0;">Welcome to Powys PA Web!</h1>
            <section class="carousel-container" aria-label="Powys PA Carousel">
                <div class="carousel">
                    <div id="slide-1" class="slide" style="display:''">
                        <div class="slide-img">
                            <img src="/resources/Councils/Powys/img/home1.jpg" alt="">
                        </div>
                        <div class="slide-text slide-text-first">
                            <h2>Welcome to Powys PA Web!</h2>
                            <p>Find information and advice about working as or employing a Personal Assistant in Care. 
                            You can also browse PA Profiles, local vacancies and send messages.</p>
                            <a class="plain-btn"  href="/s4s/CustomPage/Index/${Urls.about}" aria-describedby="slide-1">Learn more</a>
                        </div>
                    </div>
                    <div id="slide-2" class="slide" style="display:none">
                        <div class="slide-img">
                            <img src="/resources/Councils/Powys/img/home2.jpg" alt="">
                        </div>
                        <div class="slide-text slide-text-second">
                            <h2>Welcome to Powys PA Web!</h2>
                            <p>Considering becoming a Personal Assistant in Care? Register for a profile on the website or find out more.</p>
                            <a class="plain-btn"  href="/s4s/CustomPage/Index/${Urls.register}" aria-describedby="slide-2">Learn more</a>
                        </div>
                    </div>
                   
                    <button class="prev-btn" aria-label="Previous Slide"><i class="fas fa-angle-left"></i></button>
                    <div class="carousel-nav">
                        <button class="js-pause-carousel selected" aria-label="Play carousel"><i class="fas fa-play"></i></button>
                        <div class="dots">
                            <button class="dot activeSlide"></button>
                            <button class="dot"></button>
                        </div>
                    </div>
                    <button class="next-btn" aria-label="Next Slide"><i class="fas fa-angle-right"></i></button>
                </div>
            </section>

            <section class="looking-for-container" aria-label="What do you need help with">
                <h2>I am looking for?</h2>
                <div class="looking-for-content">
                    <article id="article-1" class="looking-for-tile">
                        <div>
                            <img src="/resources/Councils/Powys/img/looking-for-pa.png" alt="">
                            <h3 aria-labelledby="article-1">I am looking for a PA</h3>
                            <p>Find information about employing a Personal Assistant and browse our bank of PAs</p>
                        </div>
                        <div>
                            <a class="plain-btn" href="/s4s/CustomPage/Index/${Urls.pa_search}" aria-describedby="article-1">Find a PA</a>
                        </div>                    
                    </article>
                    <article id="article-2" class="looking-for-tile">
                        <div>
                            <img src="/resources/Councils/Powys/img/looking-for.png" alt="">
                            <h3 aria-labelledby="article-2">I am a PA</h3>
                            <p>Find information about working as a Personal Assistant and browse our bank of job vacancies</p>
                        </div>
                        <div>
                            <a class="plain-btn" href="/s4s/CustomPage/Index/${Urls.pa_registration}" aria-describedby="article-2">Find a Job</a>
                        </div>
                    </article>
                    <article id="article-3" class="looking-for-tile">
                        <div>
                            <img src="/resources/Councils/Powys/img/looking-for-info.png" alt="">
                            <h3 aria-labelledby="article-3">I'd just like some information</h3>
                            <p>Please contact Allocated worker or Powys County Council</p></div>
                        <div>
                            <a class="plain-btn" href="mailto:sepa.support@powys.gov.uk" aria-describedby="article-3">Contact</a>
                        </div>
                    </article>
                </div>
            </section>

            <section class="available-container" aria-label="Available PAs">
                <h2>Right now there are...</h2>
                <div class="available-content">
                    <div class="available-tile">
                        <div class="available-tile-text js-available-pa">
                            <span class="h3"></span>
                            <p></p>
                            <div>
                                <a class="plain-btn"  href="/s4s/CustomPage/Index/${Urls.pa_search}">Search for PA</a>
                            </div>
                        </div>
                        <div class="available-tile-image">
                            <img src="/resources/Councils/Powys/img/PA.png" alt="">
                        </div>
                    </div>
                </div>
            </section>

            `
        )
    }

    function checkAuth() {   
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('page');
    
        if (myParam === 'home') {
          createPage()
        } else {
          // Check if the user is employee
            if ($.sc.authSettings.isEmployee === true) {
                window.location = "/s4s/CustomPage/Index/" + Urls.powys_dashboard;
            } else {
                createPage()
            } 
        }
    
    }
   

    var Attributes = {
        init: function () {
            this.adjustDom();
            this.bindUIActions();
            this.unload();
        },

        adjustDom: function () {

            checkAuth()        
            const slides = document.querySelectorAll('.slide');
            const dots = document.querySelectorAll('.dot');
            const prevBtn = document.querySelector('.prev-btn');
            const nextBtn = document.querySelector('.next-btn');
            const pauseBtn = document.querySelector('.js-pause-carousel');

            let currentIndex = 0;
            let autoplayInterval;

            function showSlide(index) {
                slides.forEach((slide, i) => {
                    slide.style.display = i === index ? '' : 'none';
                });

                dots.forEach((dot, i) => {
                    dot.classList.toggle('activeSlide', i === index);
                });

                if ( localStorage.getItem('language') === 'cym') {
                    // Carousel 
                    if ($('.slide-text-first p:contains("Find information and advice about working as ")').length) {
                        $('.slide-text-first p').text('Dod o hyd i wybodaeth a chyngor am weithio fel neu gyflogi Cynorthwy-ydd Personol mewn Gofal. Gallwch hefyd bori Proffiliau Cynorthwywyr Personol, swyddi gwag lleol ac anfon negeseuon.');
                    }
                     if ($('.slide-text-second p:contains("Considering becoming a Personal Assistant")').length) {
                        $('.slide-text-second p').text('Yn ystyried dod yn Gynorthwy-ydd Personol mewn Gofal? Cofrestrwch i gael proffil ar y wefan neu dysgwch fwy.');
                    }                      
                }
            }

            function prevSlide() {
                currentIndex = (currentIndex - 1 + slides.length) % slides.length;
                showSlide(currentIndex);
            }

            function nextSlide() {
                currentIndex = (currentIndex + 1) % slides.length;
                showSlide(currentIndex);
            }

            function startAutoplay() {
                autoplayInterval = setInterval(nextSlide, 4000); // Change slide every 4 seconds
            }

            function stopAutoplay() {
                clearInterval(autoplayInterval);
            }

            function createPlayBtn() {
                pauseBtn.innerHTML = '<i class="fas fa-play"></i>'
            }

            function createPauseBtn() {
                pauseBtn.innerHTML = '<i class="fas fa-pause"></i>'
            }

            dots.forEach((dot, i) => {
            dot.addEventListener('click', () => {
                stopAutoplay();
                showSlide(i);
            });
            });

            prevBtn.addEventListener('click', () => {
                stopAutoplay();
                prevSlide();
            });

            nextBtn.addEventListener('click', () => {
                stopAutoplay();
                nextSlide();
            });

            // pauseBtn.addEventListener('click', () => {

            //     if ( $(this).hasClass('selected')) {
            //         startAutoplay();
            //         createPauseBtn();
            //         //$(this).removeClass('selected')
            //         pauseBtn.classList.add("selected");

            //     } else {
            //         stopAutoplay();
            //         createPlayBtn();
            //         //$(this).addClass('selected')
            //         pauseBtn.classList.remove("selected");
            //     }

            // });

            $('body').on('click', '.js-pause-carousel', function(){
                if ( $(this).hasClass('selected')) {
                    startAutoplay();
                    createPauseBtn();
                    $(this).attr('aria-label', 'Pause carousel');
                    $(this).removeClass('selected');

                } else {
                    stopAutoplay();
                    createPlayBtn();
                    $(this).attr('aria-label', 'Play carousel');
                    $(this).addClass('selected')
                }
            });

            // startAutoplay();
            // const languageStored = localStorage.getItem('language');

            if ( localStorage.getItem('language') === 'cym') {            
                // Carousel 
                cym('Welcome to Powys PA Web!', 'Croeso i We Cynorthwywyr Personol Powys!');
                cym('Find information and advice about working as or employing a Personal Assistant in Care. '+ 
                'You can also browse PA Profiles, local vacancies and send messages.', 'Dod o hyd i wybodaeth a chyngor am weithio fel neu gyflogi Cynorthwy-ydd Personol mewn Gofal. Gallwch hefyd bori Proffiliau Cynorthwywyr Personol, swyddi gwag lleol ac anfon negeseuon.');
                cym('Considering becoming a Personal Assistant in Care? Register for a profile on the website or find out more.', 'Yn ystyried dod yn Gynorthwy-ydd Personol mewn Gofal? Cofrestrwch i gael proffil ar y wefan neu dysgwch fwy.');
                cym('Learn more', 'Dysgu mwy');      
                
                // Tiles
                cym('I am looking for a PA', `Rwy'n edrych am Gynorthwy-ydd Personol`);
                cym('I am looking for', 'Rwy’n edrych am');
                
             
               
                cym('Find information about employing a Personal Assistant and browse our bank of PAs', 'Dod o hyd i wybodaeth am gyflogi cynorthwy-ydd personol a phorwch drwy ein banc o Gynorthwywyr Personol');
                cym('I am a PA', 'Rwy\'n Gynorthwy-ydd Personol');
                cym('Find information about working as a Personal Assistant and browse our bank of job vacancies', 'Dod o hyd i wybodaeth am weithio fel cynorthwy-ydd personol a phorwch drwy ein cronfa o swyddi gwag');
                cym('I\'d just like some information', 'Hoffwn ychydig o wybodaeth');
                cym('Please contact Allocated worker or Powys County Council', 'Cysylltwch â Gweithiwr Dynodedig neu Gyngor Sir Powys');
                cym('Right now there are...', 'Ar hyn o bryd mae...');
                cym('Available PAs in your area', 'Cynorthwy-ydd Personol ar gael yn eich ardal');
                cym('Search for PA', 'Chwilio am Gynorthwy-ydd Personol');
                cym('Available jobs', 'Swyddi sydd ar gael');
                cym('Coming soon', 'Dod yn fuan');   
                cym('Contact', 'Cysylltu');  
                cym('Find a PA', 'Chwiliwch am gynorthwy-ydd personol');
                cym('Find a Job', 'Chwiliwch am swydd');
                
               
                // Carousel 
                if ($('.slide-text-first p:contains("Find information and advice about working as ")').length) {
                    $('.slide-text-first p').text('Dod o hyd i wybodaeth a chyngor am weithio fel neu gyflogi Cynorthwy-ydd Personol mewn Gofal. Gallwch hefyd bori Proffiliau Cynorthwywyr Personol, swyddi gwag lleol ac anfon negeseuon.');
                }
                    if ($('.slide-text-second p:contains("Considering becoming a Personal Assistant")').length) {
                    $('.slide-text-second p').text('Yn ystyried dod yn Gynorthwy-ydd Personol mewn Gofal? Cofrestrwch i gael proffil ar y wefan neu dysgwch fwy.');
                }     

                document.title = 'Hafan'
    
                
            }

        },

        bindUIActions: function () {
            // translationClick()const jsEngBtn = document.querySelector('.js-eng-btn');
        
        },

        unload: function () {},
    };

    Attributes.init();
})();