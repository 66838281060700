export function cym(eng, cym) {
    var re = new RegExp(eng, "g");
    
    // Get all text nodes within the body element
    var textNodes = document.createTreeWalker(
      document.body,
      NodeFilter.SHOW_TEXT,
      null,
      false
    );
  
    while (textNodes.nextNode()) {
      var node = textNodes.currentNode;
      // Check if the text is not part of an attribute
      if (!node.parentElement || node.parentElement.nodeName !== "href") {
        node.nodeValue = node.nodeValue.replace(re, cym);
      }
    }
  }